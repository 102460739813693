import ApiService from "../../../core/services/api.service";

const apiService = ApiService;

export default class RoleCustomPermission {
  #api = null;

  constructor() {
    this.#api = `admin/role`;
  }

  getAllSubmenuPermissions() {
    let url = `${this.#api}/custom-permission/submenu/permission-list`;
    return apiService.get(url);
  }

  savePermission(data) {
    let url = `${this.#api}/custom-permission/submenu/save-permission`;
    return apiService.post(url, data);
  }

  userPermissionList() {
    let url = `${this.#api}/custom-permission/submenu/user-permission-list`;
    return apiService.get(url);
  }

  assignedPermissionToRole(id) {
    let url = `${
      this.#api
    }/custom-permission/submenu/role/${id}/assigned-permission`;
    return apiService.get(url);
  }

  rebootPermission() {
    let url = `${this.#api}/custom-permission/submenu/reboot-permission`;
    return apiService.get(url);
  }
}

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
import _ from "lodash";
Vue.prototype._ = _;

// Vue 3rd party plugins
import CKEditor from "ckeditor4-vue";
import VueConfirmDialog from "vue-confirm-dialog";
import Snotify, { SnotifyPosition } from "vue-snotify";
import VueLazyLoad from "vue-lazyload";
import LightBox from "vue-image-lightbox";
import "vue-snotify/styles/material.css";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import GeneralMixin from "@/mixins/GeneralMixin";
import Vuelidate from "vuelidate";
import RouterTab from "vue-router-tab";
import "vue-router-tab/dist/lib/vue-router-tab.css";

//Snotify
const options = {
  toast: {
    position: SnotifyPosition.rightTop
  }
};
Vue.use(GeneralMixin);
Vue.use(Snotify, options);
Vue.use(VueConfirmDialog);
Vue.use(VueLazyLoad);
Vue.use(CKEditor);
Vue.use(Vuelidate);
Vue.use(RouterTab);
Vue.component("light-box", LightBox);

// API service init
ApiService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  if (to.name !== "login" && to.name !== "logout") {
    Promise.all([store.dispatch(VERIFY_AUTH, to)]).then(next);
    if (
      !store.state.UserPermission.permissions.length &&
      store.getters.isAuthenticated
    ) {
      store.dispatch("GET_USER_PERMISSIONS").then(next);
    }
    // if (this.checkIsAccessible(to.name, "list")) {
    //   next();
    // } else {
    //   next({ name: "dashboard" });
    // }

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  } else {
    Promise.all([to]).then(next);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG).then(() => {});

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

if (!window.localStorage.getItem("XSRF-TOKEN")) {
  ApiService.get("/sanctum/csrf-cookie").then(() => {});
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");

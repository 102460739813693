// action types
export const UPDATE_ATTRIBUTE = "updateAttribute";

// mutation types
export const SET_ATTRIBUTE = "setAttribute";

const state = {
  attribute: []
};

const getters = {
  currentAttribute(state) {
    return state.attribute;
  }
};

const actions = {
  /**
   * Set the breadcrumbs list
   * @param state
   * @param payload
   */
  [UPDATE_ATTRIBUTE](state, payload) {
    state.commit(SET_ATTRIBUTE, payload);
  },

  /**
   * Set the set attribute
   * @param state
   * @param payload
   */
  [SET_ATTRIBUTE](state, payload) {
    state.commit(SET_ATTRIBUTE, payload);
  }
};

const mutations = {
  [SET_ATTRIBUTE](state, payload) {
    state.attribute = [];

    if (payload.ingredients) {
      state.attribute.push({
        name: "Ingredients",
        value: "ingredients"
      });
    }
    if (payload.is_allergen) {
      state.attribute.push({
        name: "Allergen",
        value: "is_allergen"
      });
    }
    if (payload.is_allergen_present) {
      state.attribute.push({
        name: "Allergen may be Present",
        value: "is_allergen_present"
      });
    }
    if (payload.is_nutrition_info) {
      state.attribute.push({
        name: "Nutrition Information",
        value: "is_nutrition_info"
      });
    }
    if (payload.product_warning) {
      state.attribute.push({
        name: "Product Warnings",
        value: "product_warning"
      });
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};

import RoleCustomPermission from "@/service/user/role/RoleCustomPermission";

const roleCustomService = new RoleCustomPermission();
const UserPermissionModule = {
  state: {
    permissions: []
  },
  getters: {
    getPermissions(state) {
      return state.permissions;
    }
  },
  actions: {
    GET_USER_PERMISSIONS: context => {
      let config = JSON.parse(localStorage.getItem("conf"));
      if (config && config.length > 0) {
        context.commit("SET_USER_PERMISSIONS", config);
      } else {
        roleCustomService
          .userPermissionList()
          .then(response => {
            localStorage.setItem(
              "conf",
              JSON.stringify(response.data.permissions)
            );
            context.commit("SET_USER_PERMISSIONS", response.data.permissions);
          })
          .catch(() => {
            localStorage.removeItem("conf");
          });
      }
    }
  },
  mutations: {
    SET_USER_PERMISSIONS: (state, payload) => {
      state.permissions = payload;
    }
  }
};
export default UserPermissionModule;

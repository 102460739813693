import Vue from "vue";
import { RouterTabRoutes } from "vue-router-tab";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        ...RouterTabRoutes,
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: {
            title: "Dashboard",
            icon: "menu-icon flaticon2-architecture-and-city",
            closable: false
          }
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue"),
          meta: {
            title: "Builder",
            icon: "menu-icon flaticon2-architecture-and-city"
          }
        },
        {
          path: "/banner",
          name: "banner",
          component: () => import("@/view/pages/banner/Banner.vue"),
          meta: {
            title: "Banner",
            icon: "menu-icon flaticon2-image-file"
          }
        },
        {
          path: "/banner/create",
          name: "banner-create",
          component: () => import("@/view/pages/banner/CreateOrUpdate"),
          meta: {
            title: "Add Banner",
            icon: "menu-icon flaticon2-image-file"
          }
        },
        {
          path: "/admin",
          name: "admin",
          component: () => import("@/view/pages/admin/AdminUsers.vue"),
          meta: {
            title: "Admin User",
            icon: "menu-icon flaticon2-user",
            closable: true,
            keepAlive: true
          }
        },
        {
          path: "/admin/profile/:id",
          name: "admin-profile",
          component: () => import("@/view/pages/admin/Profile.vue"),
          meta: {
            title: "Admin User Profile",
            icon: "menu-icon flaticon2-user",
            closable: true,
            keepAlive: true
          }
        },
        {
          path: "/users",
          name: "users",
          component: () => import("@/view/pages/users/Index.vue"),
          meta: {
            title: "Customer",
            icon: "menu-icon flaticon-users-1",
            closable: true,
            keepAlive: true
          }
        },
        // {
        //   path: "/users/profile/:id",
        //   name: "users-profile",
        //   props: {
        //     default: true
        //   },
        //   component: () => import("@/view/pages/users/Profile.vue")
        // },
        {
          path: "/role",
          name: "role",
          component: () => import("@/view/pages/admin/role/Index.vue"),
          meta: {
            title: "Role",
            icon: "menu-icon flaticon2-user-1",
            closable: true,
            keepAlive: true
          }
        },
        {
          path: "/role/:id/permission",
          name: "role-permission",
          component: () =>
            import("@/view/pages/admin/permission/ManageRolePermission.vue"),
          meta: {
            title: "Dashboard",
            icon: "menu-icon flaticon2-user-1"
          }
        },
        {
          path: "/permission",
          name: "permission",
          component: () => import("@/view/pages/admin/permission/Index.vue"),
          meta: {
            title: "Dashboard",
            icon: "menu-icon mdi mdi-account-key"
          }
        },
        {
          path: "/role/:id/custom-permission",
          name: "custom-permission",
          component: () =>
            import(
              "@/view/pages/admin/permission/ManageRoleCustomPermission.vue"
            ),
          meta: {
            title: "Custom Permission",
            icon: "menu-icon mdi mdi-account-key"
          }
        },
        {
          path: "/faq",
          name: "faq",
          component: () => import("@/view/pages/cms/faq/Index.vue"),
          meta: {
            title: "Faq",
            icon: "menu-icon flaticon-questions-circular-button"
          }
        },
        {
          path: "faq-category",
          name: "faq-category",
          component: () => import("@/view/pages/cms/faq/category/Index.vue"),
          meta: {
            title: "Faq Category",
            icon: "menu-icon flaticon-questions-circular-button"
          }
        },
        {
          path: "/notice",
          name: "notice",
          component: () => import("@/view/pages/cms/notice/Index.vue"),
          meta: {
            title: "Notice",
            icon: "menu-icon flaticon2-mail"
          }
        },
        {
          path: "/popup",
          name: "popup",
          component: () => import("@/view/pages/cms/popup/Index.vue"),
          meta: {
            title: "Popup",
            icon: "menu-icon fas fa-external-link-alt"
          }
        },
        {
          path: "/blog",
          name: "blog",
          component: () => import("@/view/pages/cms/blog/Index.vue"),
          meta: {
            title: "Blog",
            icon: "menu-icon fas fa-bars"
          }
        },
        {
          path: "/blog/:blogId/edit",
          name: "blog-edit",
          component: () => import("@/view/pages/cms/blog/CreateAndUpdate.vue"),
          meta: {
            title: "Update Blog",
            icon: "fas fa-pencil-alt",
            key: "fullPath"
          }
        },
        {
          path: "/blog/create",
          name: "blog-create",
          component: () => import("@/view/pages/cms/blog/CreateAndUpdate.vue"),
          meta: {
            title: "Add Blog",
            icon: "fas fa-plus"
          }
        },
        {
          path: "/blog-category",
          name: "blog-category",
          component: () => import("@/view/pages/cms/blog/category/Index.vue"),
          meta: {
            icon: "menu-icon fas fa-bars",
            title: "Blog Category"
          }
        },
        {
          path: "/slider",
          name: "slider",
          component: () => import("@/view/pages/cms/slider/Index.vue"),
          meta: {
            title: "Slider",
            icon: "menu-icon flaticon2-image-file"
          }
        },
        {
          path: "/slider-create",
          name: "slider-create",
          component: () =>
            import("@/view/pages/cms/slider/CreateAndUpdate.vue"),
          meta: {
            title: "Create Slider",
            icon: "menu-icon flaticon2-image-file"
          }
        },
        {
          path: "/slider/:id/edit",
          name: "slider-edit",
          component: () =>
            import("@/view/pages/cms/slider/CreateAndUpdate.vue"),
          meta: {
            title: "Update Slider",
            icon: "menu-icon flaticon2-image-file"
          }
        },
        {
          path: "testimonial",
          name: "testimonial",
          meta: {
            title: "Testimonials",
            icon: "menu-icon fas fa-people-arrows"
          },
          component: () => import("@/view/pages/cms/testimonial/Index.vue")
        },
        {
          path: "testimonial/create",
          name: "testimonial-create",
          meta: {
            title: "Add Testimonial",
            icon: "menu-icon fas fa-people-arrows"
          },
          component: () =>
            import("@/view/pages/cms/testimonial/CreateOrUpdate.vue")
        },

        {
          path: "testimonial/:id",
          name: "testimonial-update",
          meta: {
            title: "Update Testimonial",
            icon: "menu-icon fas fa-people-arrows"
          },
          component: () =>
            import("@/view/pages/cms/testimonial/CreateOrUpdate.vue")
        },
        {
          path: "/email-template",
          name: "email-template",
          component: () =>
            import("@/view/pages/settings/email-template/Roles.vue"),
          meta: {
            title: "Email Template",
            icon: "menu-icon menu-icon flaticon2-mail-1"
          }
        },
        {
          path: "/email-template/:role",
          name: "email-template-role",
          component: () =>
            import("@/view/pages/settings/email-template/Index.vue"),
          meta: {
            title: "Email Template Role",
            icon: "menu-icon menu-icon flaticon2-mail-1"
          }
        },
        {
          path: "/email-template/:id/update",
          name: "email-template-update",
          component: () =>
            import("@/view/pages/settings/email-template/CreateAndUpdate.vue"),
          meta: {
            title: "Email Template Update",
            icon: "menu-icon menu-icon flaticon2-mail-1"
          }
        },
        {
          path: "/level",
          name: "level",
          component: () => import("@/view/pages/level/Index.vue"),
          meta: {
            title: "Level",
            icon: "menu-icon menu-icon fas fa-book"
          }
        },
        {
          path: "/grade",
          name: "grade",
          component: () => import("@/view/pages/grade/Index.vue"),
          meta: {
            title: "Grade",
            icon: "menu-icon menu-icon fas fa-book"
          }
        },
        {
          path: "/subject",
          name: "subject",
          component: () => import("@/view/pages/subject/Index.vue"),
          meta: {
            title: "Subject",
            icon: "menu-icon menu-icon fas fa-book"
          }
        },
        {
          path: "/faculty",
          name: "faculty",
          component: () => import("@/view/pages/faculty/Index.vue"),
          meta: {
            title: "Faculty",
            icon: "menu-icon menu-icon fas fa-book"
          }
        },
        {
          path: "/program",
          name: "program",
          component: () => import("@/view/pages/program/Index.vue"),
          meta: {
            title: "Program",
            icon: "menu-icon menu-icon fas fa-book"
          }
        },
        {
          path: "/semester-and-year",
          name: "semester-and-year",
          component: () => import("@/view/pages/semester-and-year/Index.vue"),
          meta: {
            title: "Semester And Year",
            icon: "menu-icon menu-icon fas fa-book"
          }
        },
        {
          path: "/note",
          name: "note",
          component: () => import("@/view/pages/note/Index.vue"),
          meta: {
            title: "Note",
            icon: "menu-icon menu-icon fas fa-book"
          }
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "reset-password",
          path: "/reset-password",
          component: () => import("@/view/pages/auth/ResetPassword")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});

import { mapActions } from "vuex";

const GeneralMixin = {
  methods: {
    ...mapActions(["GET_USER_PERMISSIONS"]),
    checkIsAccessible(menu, subMenu = "") {
      let permissions = this.$store.getters.getPermissions;
      if (permissions[menu] && permissions[menu].length > 0) {
        return permissions[menu].indexOf(subMenu) !== -1;
      } else return false;
    }
  }
};

export default {
  install(Vue) {
    Vue.mixin(GeneralMixin);
  }
};
